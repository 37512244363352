<script>
    import { formFields } from "../helpers";

    export default {
        name: "Pricing Selector",

        data() {
            return {
                emailAddress: '',
                subscriptions: [],
                plans: [],
                addons: [],
                subscriptionIds: [],
                planId: null,
                planUsersCount: 1,
                enterprisePlanSelected: false,
                addonIds: [],
                annualBilling: false,
            };
        },

        mounted() {
            let rootEl = this.$el.parentElement;
            let defaultData = JSON.parse(rootEl.getAttribute('data-pricing-selector'));

            this.emailAddress = defaultData?.emailAddress ?? this.emailAddress;

            this.subscriptions = (defaultData?.subscriptions ?? this.subscriptions).map(plan => {
                plan.price = plan.price ? parseFloat(plan.price) : null;

                if (plan.always_selected) {
                    this.subscriptionIds.push(plan.id);
                }

                return plan;
            });

            this.plans = (defaultData?.plans ?? this.plans).map(plan => {
                plan.minUsers = plan.minUsers ? parseFloat(plan.minUsers) : null;
                plan.maxUsers = plan.maxUsers ? parseFloat(plan.maxUsers) : null;

                return plan;
            });

            this.addons = (defaultData?.addons ?? this.addons).map(plan => {
                plan.price = plan.price ? parseFloat(plan.price) : null;

                return plan;
            });
        },

        computed: {
            selectedSubscriptions() {
                return this.subscriptions.filter(subscription => this.subscriptionIds.includes(subscription.id));
            },

            selectedPlan() {
                return this.plans.find(plan => plan.id === this.planId);
            },

            selectedAddons() {
                return this.addons
                    .filter(addon => this.addonIds.includes(addon.id))
                    .map(addon => {
                        addon.total = addon.price * addon.users;

                        return addon;
                    })
            },

            allOptionsSelected() {
                return this.subscriptionIds.length > 0 && this.planId;
            },

            total() {
                if (!this.allOptionsSelected) {
                    return 0;
                }

                let total = 0,
                    subscriptionPrice = 0;

                this.selectedSubscriptions.forEach(subscription => {
                    total += subscription.price;
                    subscriptionPrice += subscription.price;
                });


                total = total * this.planUsersCount;
                subscriptionPrice = subscriptionPrice * this.planUsersCount;

                if (this.annualBilling) {
                    total = (total * 12) - ((total * 12) * 0.05);

                    this.selectedAddons.forEach(addon => {
                        total += (addon.price * addon.users) * 12;
                    });
                } else {
                    this.selectedAddons.forEach(addon => {
                        total += addon.price * addon.users;
                    });
                }

                return total;
            },

            emailUrl() {
                if (!this.allOptionsSelected) {
                    return encodeURI(`mailto:${this.emailAddress}?subject=Subscription Inquiry`);
                }

                if (this.enterprisePlanSelected) {
                    return encodeURI(`mailto:${this.emailAddress}?subject=Subscription Inquiry&body=I would like to subscribe to the Enterprise plan.`);
                }

                return encodeURI(`mailto:${this.emailAddress}?subject=Subscription Inquiry&body=I would like to subscribe.\n\nProducts: ${this.selectedSubscriptions.map(subscription => subscription.title.replace(/<\/?[^>]+(>|$)/g, "")).join(', ')}${this.selectedAddons.length > 0 ? `\n\nLive Prices: ${this.selectedAddons.map(addon => addon.title.replace(/<\/?[^>]+(>|$)/g, "")).join(', ')}` : ''}.\n\nPlan: ${this.selectedPlan.title.replace(/<\/?[^>]+(>|$)/g, "")}.\n\nUsers: ${this.planUsersCount}.\n\nAnnual Billing: ${this.annualBilling ? `Yes` : `No`}.\n\nTotal: $${this.total}`);
            }
        },

        watch: {
            selectedPlan() {
                this.planUsersCount = (this.selectedPlan.minUsers ? this.selectedPlan.minUsers : 1);

                this.enterprisePlanSelected = this.selectedPlan.title == 'Enterprise' ? true : false;
            },
        },

        updated() {
            formFields();
        },

        methods: {
            formatPrice(amount) {
                let money = Number.parseFloat(Math.ceil(amount * 100) / 100).toFixed(2);

                return new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                }).format(money).replace('.00', '');
            },
        }
    };
</script>
